<template>
  <v-card class="my-6">
    <v-card-title>
      {{'sms.action.send'| t}} ({{$t(`sms.service.${service}`)}})
    </v-card-title>

    <v-card-text>
      <v-text-field
        dense
        outlined
        class="mb-4"
        :label="$t('sms.data.phone_number')"
        :rules="phoneNumberRule"
        :messages="phoneNumberHelp"
        v-model="data.phone_number"
      ></v-text-field>

      <v-textarea
        dense
        outlined
        :label="$t('sms.data.message')"
        maxlength="500"
        counter
        :rules="[
          v => $helper.validator.required(v)
        ]"
        v-model="data.message"
      ></v-textarea>
    </v-card-text>

    <v-card-actions>
      <v-btn
        @click="send"
        class="green white--text"
        :disabled="sendDisabled"
        :loading="loading"
      >
        {{'sms.action.send'| t}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="babel" type="text/babel">
export default {
  data: () => ({
    data: {
      phone_number: null,
      message: null,
      send_at: null,
    },
    loading: false,
  }),
  methods: {
    async send() {
      if(this.sendDisabled) return
      this.loading = true
      try {
        const result = await this.$api.collection.smsApi.send(this.data)
        this.handleResult(result)
      } catch (error) {
        console.error(error)
        this.$snotify.error(this.$t('error.unexpect'), this.$t('sms.send.successfully'))
      } finally {
        this.loading = false
      }
    },
    handleResult(result) {
      const success = result.status === this.$smsConstants.STATUS_SUCCESS
      if(success) {
        this.$snotify.success(null, this.$t('sms.send.successfully'))
      }

      else {
        this.$snotify.error(null, this.$t('sms.send.failure'))
      }

      this.$apopup.base({
        title: this.$t('sms.send.read_more'),
        applyCallback: () => {
          const url = this.$router.resolve({
            name: 'sms-update', params: { target: result.id }
          }).href
          window.open(url)
        },
      })
    },
  },
  computed: {
    phoneNumberHelp() {
      if(!this.isGlobalService) return null
      return this.$t('sms.help.global')
    },
    siteConfig() {
      return this.$store.getters['base/siteConfig']
    },
    isGlobalService() {
      return this.siteConfig.sms.global === true
    },
    service() {
      return this.siteConfig.sms.service
    },
    phoneNumberRule() {
      if(this.isGlobalService) {
        return [v => this.$helper.validator.phoneNumberGlobalRule(v)]
      }
      if(!this.isGlobalService) {
        return [v => this.$helper.validator.phoneNumberLocalRule(v)]
      }
      return null
    },
    sendDisabled() {
      if(!this.$helper.validator.required(this.data.message)) return true
      if(this.isGlobalService) {
        if(!this.$helper.validator.phoneNumberGlobal(this.data.phone_number)) return true
      }
      if(!this.isGlobalService) {
        if(!this.$helper.validator.phoneNumberLocal(this.data.phone_number)) return true
      }
      return false
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>